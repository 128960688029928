@use "sass:math";

//   
.custom-control {
  position: relative;
  z-index: 1;
  display: inline-flex;
  min-height: $checkbox-radio-switch-size;
  padding-left: $custom-control-gutter + $custom-control-indicator-size;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: $custom-control-spacer-x;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1; // Put the input behind the label so it doesn't overlay text
  width: $custom-control-indicator-size;
  height: math.div($font-size-base * $line-height-base + $custom-control-indicator-size , 2);
  opacity: 0;

  &:checked ~ .custom-control-label::before {
    color: $custom-control-indicator-checked-color;
    border-color: $custom-control-indicator-checked-border-color;
    background-color: $custom-control-indicator-checked-bg;
    box-shadow: $custom-control-indicator-checked-box-shadow;
  }

  &:focus ~ .custom-control-label::before {

    // the mixin is not used here to make sure there is feedback
    @if $enable-shadows {
      box-shadow: $input-box-shadow, $input-focus-box-shadow;
    }

    @else {
      box-shadow: $custom-control-indicator-focus-box-shadow;
    }
  }

  &:focus:not(:checked) ~ .custom-control-label::before {
    border-color: $custom-control-indicator-focus-border-color;
  }

  &:not(:disabled):active ~ .custom-control-label::before {
    color: $custom-control-indicator-active-color;
    background-color: $custom-control-indicator-active-bg;
    border-color: $custom-control-indicator-active-border-color;
    box-shadow: $custom-control-indicator-active-box-shadow;
  }

  // Use [disabled] and :disabled to work around https://github.com/twbs/bootstrap/issues/28247
  &[disabled],
  &:disabled {
    ~ .custom-control-label {
      color: $custom-control-label-disabled-color;
    }
  }
}

// Custom control indicators
//
// Build the custom controls out of pseudo-elements.

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  color: $custom-control-label-color;
  vertical-align: top;
  cursor: $custom-control-cursor;

  &:before,
  &:after {
    z-index: 1;
  }

  // Background-color and (when enabled) gradient
  &::before {
    position: absolute;
    top: math.div($font-size-base * $line-height-base - $custom-control-indicator-size , 2);
    left: -($custom-control-gutter + $custom-control-indicator-size);
    display: block;
    width: $custom-control-indicator-size;
    height: $custom-control-indicator-size;
    pointer-events: none;
    content: "";
    background-color: $custom-control-indicator-bg;
    border: $custom-control-indicator-border-color solid $custom-control-indicator-border-width;
    box-shadow: $custom-control-indicator-box-shadow;
    border-radius: $custom-checkbox-indicator-border-radius;
  }

  // Foreground (icon)
  &::after {
    position: absolute;
    top: math.div($font-size-base * $line-height-base - $custom-control-indicator-size , 2);
    left: -($custom-control-gutter + $custom-control-indicator-size);
    display: block;
    width: $custom-control-indicator-size;
    height: $custom-control-indicator-size;
    content: "";
    background: no-repeat 50% / #{$custom-control-indicator-bg-size};
  }
}

.custom {
  &-control {
    display: inline-flex;
    min-height: $checkbox-radio-switch-size;

    &.color-control {
      padding: 4px !important;

      .custom-control-label {
        &::before {
          opacity: 0;
          display: block;
          left: -4px;
          right: -4px;
          top: -4px;
          bottom: -4px;
          border-radius: 50%;
          border: 2px solid $primary !important;
          background: transparent !important;
          transition: .3s ease;
        }

        &::after {
          display: none;
        }
      }

      .custom-control-input:checked ~ {
        .custom-control-label {
          &::before {
            opacity: 1;
          }
        }
      }
    }

    &.notext {
      margin-left: -$checkbox-radio-switch-text-gap;

      .custom-control-label {
        position: relative;
        left: $checkbox-radio-switch-text-gap;
      }
    }

    &-label {
      font-size: $checkbox-radio-switch-font-size;
      line-height: $checkbox-radio-switch-line-height;
      padding-top: math.div($checkbox-radio-switch-size - $checkbox-radio-switch-line-height, 2);

      &::before,
      &::after {
        top: 0;
      }

      &:after {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-image: none !important;
        font-family: $nk-dashlite-font;
        color: $custom-control-indicator-checked-color;
        opacity: 0;

        .custom-checkbox & {
          content: $ni-check-thick;
        }

        .custom-radio & {
          content: $ni-bullet-fill;
        }
      }
    }

    .custom-control-input {
      &:checked ~ .custom-control-label::after {
        opacity: 1;
      }

      &[disabled],
      &:disabled {
        ~ .custom-control-label {
          opacity: .7;
          color: $base-color;
        }
      }
    }

    &-sm {
      min-height: $checkbox-radio-switch-size-sm;
      padding-left: $checkbox-radio-switch-text-gap-sm + $checkbox-radio-switch-size-sm;

      &.notext {
        margin-left: -$checkbox-radio-switch-text-gap-sm;

        .custom-control-label {
          left: $checkbox-radio-switch-text-gap-sm;
        }
      }

      .custom-control-label {
        font-size: $checkbox-radio-switch-font-size-sm;
        line-height: $checkbox-radio-switch-line-height-sm;
        padding-top: 0;

        &::before,
        &::after {
          left: -($checkbox-radio-switch-text-gap-sm + $checkbox-radio-switch-size-sm);
          width: $checkbox-radio-switch-size-sm;
          height: $checkbox-radio-switch-size-sm;
        }

        &:after {
          font-size: 0.9em;
        }
      }
    }

    &-lg {
      min-height: $checkbox-radio-switch-size-lg;
      padding-left: $checkbox-radio-switch-text-gap-lg + $checkbox-radio-switch-size-lg;

      &.notext {
        margin-left: -$checkbox-radio-switch-text-gap-lg;

        .custom-control-label {
          left: $checkbox-radio-switch-text-gap-lg;
        }
      }

      .custom-control-label {
        font-size: $checkbox-radio-switch-font-size-lg;
        line-height: $checkbox-radio-switch-line-height-lg;
        padding-top: math.div($checkbox-radio-switch-size-lg - $checkbox-radio-switch-line-height-lg , 2);

        &::before,
        &::after {
          left: -($checkbox-radio-switch-text-gap-lg + $checkbox-radio-switch-size-lg);
          width: $checkbox-radio-switch-size-lg;
          height: $checkbox-radio-switch-size-lg;
        }

        &:after {
          font-size: 1.2em;
        }
      }
    }

    &-input {
      position: absolute;
      height: 1px;
      width: 1px;
      opacity: 0;
    }

    &.is-right {
      padding-left: 0;
      padding-right: $checkbox-radio-switch-text-gap + $checkbox-radio-switch-size;

      .custom-control-label {

        &:before,
        &:after {
          left: auto;
          right: - ($checkbox-radio-switch-text-gap + $checkbox-radio-switch-size);
        }
      }

      &.custom-control-sm {
        padding-right: $checkbox-radio-switch-text-gap-sm + $checkbox-radio-switch-size-sm;

        .custom-control-label {

          &:before,
          &:after {
            right: - ($checkbox-radio-switch-text-gap-sm + $checkbox-radio-switch-size-sm);
          }
        }
      }

      &.custom-control-lg {
        padding-right: $checkbox-radio-switch-text-gap-lg + $checkbox-radio-switch-size-lg;

        .custom-control-label {

          &:before,
          &:after {
            right: - ($checkbox-radio-switch-text-gap-lg + $checkbox-radio-switch-size-lg);
          }
        }
      }
    }
  }

  &-file-label {
    white-space: nowrap;
    overflow: hidden;
  }

  &-select {
    background: $field-bg;
  }
}

// custom-control-group
.custom-control {
  &-group {
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    margin: -.375rem;

    > * {
      padding: .375rem;
    }
  }

  &-stacked {
    margin: 0;

    > * {
      padding: 0;
      margin: -1px;
    }

    &:not(.custom-control-vertical) {
      > * {
        &:not(:first-child) .custom-control-label {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }

        &:not(:last-child) .custom-control-label {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }
  }

  &-vertical {
    flex-direction: column;
    align-items: start;

    > * {
      width: 100%;
    }

    .custom-control {
      display: flex;

      .custom-control-label {
        width: 100%;
      }
    }

    &.custom-control-stacked {
      > * {
        &:not(:first-child) .custom-control-label {
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }

        &:not(:last-child) .custom-control-label {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }
  }
}

// custom-control-pro
.custom-control-pro {
  padding-left: 0;
  position: relative;

  &.no-control {
    &.checked {
      z-index: 3;
    }

    &.focused {
      z-index: 2;
    }

    .custom-control-label {
      padding: ($btn-padding-y - 0.0625) $btn-padding-x;
      border-width: 2px;
    }

    .custom-control-input {
      &:checked ~ .custom-control-label {
        border-color: $custom-control-indicator-checked-border-color;
        box-shadow: $custom-control-indicator-active-box-shadow;
        z-index: 2;
      }

      &:not(:disabled):active ~ .custom-control-label {
        border-color: $custom-control-indicator-active-border-color;
        box-shadow: $custom-control-indicator-active-box-shadow;
      }

      // Use [disabled] and :disabled to work around https://github.com/twbs/bootstrap/issues/28247
      &[disabled],
      &:disabled {
        ~ .custom-control-label {
          color: $custom-control-label-disabled-color;
        }
      }
    }
  }

  &-block {
    &.custom-control {
      display: flex;
    }

    .custom-control-label {
      width: 100%;
    }
  }

  .custom-control-label {
    border: 1px solid $border-light;
    padding: $btn-padding-x $btn-padding-x $btn-padding-x (
      $custom-control-gutter + $custom-control-indicator-size + $btn-padding-x
    );
  font-size: $btn-font-size;
  line-height: $btn-line-height;
  border-radius: $btn-border-radius;
  transition: $btn-transition;
  display: inline-flex;
  align-items: center;
  background-color: $white;

  &::before,
  &::after {
    top: 50%;
    transform: translateY(-50%);
    left: $btn-padding-x;
  }

  // With Icon
  .icon {
    font-size: 1.4em;
    line-height: inherit;

    &-lg {
      font-size: 2.2em;
    }
  }

  > span {
    // display: inline-block;
    white-space: nowrap;

    &:only-child {
      width: 100%;
    }
  }

  .icon + span,
  span + .icon {
    padding-left: 8px;
  }
}

&.custom-control-sm {
  padding-left: 0;

  .custom-control-label {
    padding: (.75rem - 0.0625) $btn-padding-x (
      .75rem - 0.0625) ($checkbox-radio-switch-text-gap + $checkbox-radio-switch-size-sm + $btn-padding-x
    );
  font-size: $btn-font-size;
  line-height: $btn-line-height;
  border-radius: $btn-border-radius;

  &::before,
  &::after {
    top: 50%;
    transform: translateY(-50%);
    left: $btn-padding-x;
  }
}
}

&-icon {
  &.custom-control-pro {
    .custom-control-label {
      padding-left: 0;
      padding-right: 0;

      .icon {
        text-align: center;
        width: $btn-line-height + $btn-padding-y*2;
      }
    }
  }
}
}

// pro-ontrol
.pro-control {
  &.custom-control {
    padding-left: 0;
  }

  .custom-control-label {
    padding: 1.5rem;
    border-radius: $border-radius;
    border: 1px solid $border-light;
    overflow: hidden;
    transition: .3s ease;

    &:before,
    &:after {
      z-index: 9;
    }

    &::before {
      border-width: 1px;
      top: 1rem;
      left: 1rem;
    }

    &::after {
      top: 1rem;
      left: 1rem;
    }

    .icon-wrap {
      height: 36px;

      img {
        height: 100%;
      }

      &.lg {
        height: 48px;
      }

      &.xl {
        height: 60px;
      }
    }

    .text-center .icon {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .custom-control-input:checked ~ .custom-control-label {
    border-color: $accent-color;
  }
}

.custom-control-full {
  width: 100%;
  height: 100%;

  .custom-control-label {
    width: 100%;
    height: 100%;
  }
}

//   no-control
.no-control {
  padding-left: 0;

  .custom-control-label {

    &::before,
    &::after {
      display: none;
    }
  }
}

// Custom-checkbox
.custom-checkbox {
  .custom-control-input:disabled {
    &:checked ~ .custom-control-label::before {
      background-color: $custom-control-indicator-checked-disabled-bg;
    }

    &:indeterminate ~ .custom-control-label::before {
      background-color: $custom-control-indicator-checked-disabled-bg;
    }
  }
}

// custom-radio
.custom-radio {
  .custom-control-label::before {
    // stylelint-disable-next-line property-blacklist
    border-radius: $custom-radio-indicator-border-radius;
  }

  .custom-control-input:disabled {
    &:checked ~ .custom-control-label::before {
      background-color: $custom-control-indicator-checked-disabled-bg;
    }
  }
}

//image control
.image-control {
  &.custom-control {
    padding-left: 0;
  }

  .custom-control-input:checked ~ .custom-control-label {
    &:before {
      border-color: $white;
    }

    img {
      opacity: .8;
    }
  }

  .custom-control-label {
    padding-top: 0;
    border-radius: $border-radius;
    overflow: hidden;
    background-color: $dark;

    img {
      transition: opacity .3s ease;
    }

    &:before,
    &:after {
      z-index: 9;
    }

    &::before {
      border-width: 1px;
      top: 1rem;
      left: 1rem;
    }

    &::after {
      top: 1rem;
      left: 1rem;
    }
  }

  &:hover {
    .custom-control-label {
      img {
        opacity: .8;
      }
    }
  }
}

// custom-switch
.custom-switch {
  padding-left: $switch-width + $checkbox-radio-switch-text-gap;

  .custom-control-label {
    &::before {
      left: -($switch-width + $checkbox-radio-switch-text-gap);
      width: $switch-width;
      border-radius: $switch-indicator-border-radius;
      pointer-events: all;
    }

    &::after {
      opacity: 1;
      top: math.div($checkbox-radio-switch-size - $switch-indicator-size, 2);
      left: -3.5rem;
      width: $switch-indicator-size;
      height: $switch-indicator-size;
      border-radius: $switch-indicator-border-radius;
      background-color: $custom-control-indicator-border-color;
      transition: transform .15s ease-in-out, $custom-forms-transition;
    }
  }

  .custom-control-input:disabled {
    &:checked ~ .custom-control-label::before {
      background-color: $custom-control-indicator-checked-disabled-bg;
    }
  }

  .custom-control-input:checked ~ .custom-control-label {
    &::after {
      background-color: $custom-control-indicator-bg;
      transform: translateX($custom-switch-width - $custom-control-indicator-size);
    }
  }

  &.custom-control-sm {
    padding-left: $switch-width-sm + $checkbox-radio-switch-text-gap-sm;

    .custom-control-label {
      &::before {
        left: -($switch-width-sm + $checkbox-radio-switch-text-gap-sm);
        width: $switch-width-sm;
        border-radius: $switch-indicator-border-radius-sm;
      }

      &::after {
        top: math.div($checkbox-radio-switch-size-sm - $switch-indicator-size-sm, 2);
        left: add(-($switch-width-sm + $checkbox-radio-switch-text-gap-sm), $switch-border-calc);
        width: $switch-indicator-size-sm;
        height: $switch-indicator-size-sm;
        border-radius: $switch-indicator-border-radius-sm;
      }
    }

    .custom-control-input:checked ~ .custom-control-label {
      &::after {
        transform: translateX($switch-indicator-active-position-sm);
      }
    }
  }

  &.custom-control-lg {
    padding-left: $switch-width-lg + $checkbox-radio-switch-text-gap-lg;

    .custom-control-label {
      &::before {
        left: -($switch-width-lg + $checkbox-radio-switch-text-gap-lg);
        width: $switch-width-lg;
        border-radius: $switch-indicator-border-radius-lg;
      }

      &::after {
        top: math.div($checkbox-radio-switch-size-lg - $switch-indicator-size-lg, 2);
        left: add(-($switch-width-lg + $checkbox-radio-switch-text-gap-lg), $switch-border-calc-1-5x);
        width: $switch-indicator-size-lg;
        height: $switch-indicator-size-lg;
        border-radius: $switch-indicator-border-radius-lg;
      }
    }

    .custom-control-input:checked ~ .custom-control-label {
      &::after {
        transform: translateX($switch-indicator-active-position-lg);
      }
    }
  }

  &.is-right {
    padding-left: 0;
    padding-right: $switch-width + $checkbox-radio-switch-text-gap;

    .custom-control-label {

      &:before,
      &:after {
        left: auto;
        right: - ($checkbox-radio-switch-text-gap + $checkbox-radio-switch-size);
      }
    }

    .custom-control-label::before {
      right: - ($switch-width + $checkbox-radio-switch-text-gap);
    }

    .custom-control-label::after {
      right: add(-($switch-width + $checkbox-radio-switch-text-gap), $switch-border-calc);
    }

    .custom-control-input:checked ~ .custom-control-label {
      &::after {
        transform: translateX($switch-indicator-active-position-right);
      }
    }

    &.custom-control-sm {
      padding-right: $switch-width-sm + $checkbox-radio-switch-text-gap-sm;

      .custom-control-label::before {
        right: - ($switch-width-sm + $checkbox-radio-switch-text-gap-sm);
      }

      .custom-control-label::after {
        right: add(-($switch-width-sm + $checkbox-radio-switch-text-gap-sm), $switch-border-calc);
      }

      .custom-control-input:checked ~ .custom-control-label {
        &::after {
          transform: translateX($switch-indicator-active-position-right-sm);
        }
      }
    }

    &.custom-control-lg {
      padding-right: $switch-width-lg + $checkbox-radio-switch-text-gap-lg;

      .custom-control-label::before {
        right: - ($switch-width-lg + $checkbox-radio-switch-text-gap-lg);
      }

      .custom-control-label::after {
        right: add(-($switch-width-lg + $checkbox-radio-switch-text-gap-lg), $switch-border-calc-1-5x);
      }

      .custom-control-input:checked ~ .custom-control-label {
        &::after {
          transform: translateX($switch-indicator-active-position-right-lg);
        }
      }
    }
  }
}


label {
  cursor: pointer;

  input[type="radio"]:checked ~ & {
    cursor: default;
  }
}


//validation

.is-alter .form-control ~ ,
.is-alter .input-group ~ ,
.custom-control {
  .invalid {
    position: absolute;
    left: 0;
    color: $white;
    font-size: $fx-sz-11;
    line-height: 1;
    bottom: calc(100% + 4px);
    background: lighten($danger, 8%);
    padding: .3rem .5rem;
    z-index: 1;
    border-radius: $border-radius-sm;
    white-space: nowrap;

    &:before {
      position: absolute;
      content: '';
      height: 0;
      width: 0;
      border-left: 6px solid lighten($danger, 8%);
      border-right: 6px solid transparent;
      left: 10px;
      border-bottom: 6px solid transparent;
      bottom: -6px;
    }
  }
}

.is-alter .form-control ~ ,
.is-alter .input-group ~ {
  .invalid {
    left: auto;
    right: 0;

    &::before {
      left: auto;
      right: 10px;
      border-right-color: lighten($danger, 8%);
      border-left-color: transparent;
      bottom: -4px;
    }
  }
}

// 
.form-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: $custom-file-height;
  margin-bottom: 0;
}

.form-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: $custom-file-height;
  margin: 0;
  opacity: 0;

  &:focus ~ .form-file-label {
    border-color: $custom-file-focus-border-color;
    box-shadow: $custom-file-focus-box-shadow;
  }

  // Use [disabled] and :disabled to work around https://github.com/twbs/bootstrap/issues/28247
  &[disabled] ~ .form-file-label,
  &:disabled ~ .form-file-label {
    background-color: $custom-file-disabled-bg;
  }

  @each $lang,
  $value in $custom-file-text {
    &:lang(#{$lang}) ~ .form-file-label::after {
      content: $value;
    }
  }

  ~ .form-file-label[data-browse]::after {
    content: attr(data-browse);
  }
}

.form-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: $custom-file-height;
  padding: $custom-file-padding-y $custom-file-padding-x;
  font-family: $custom-file-font-family;
  font-weight: $custom-file-font-weight;
  line-height: $custom-file-line-height;
  color: $custom-file-color;
  background-color: $custom-file-bg;
  border: $custom-file-border-width solid $custom-file-border-color;
  @include border-radius($custom-file-border-radius);
  @include box-shadow($custom-file-box-shadow);

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: $custom-file-height-inner;
    padding: $custom-file-padding-y $custom-file-padding-x;
    line-height: $custom-file-line-height;
    color: $custom-file-button-color;
    content: "Browse";
    @include gradient-bg($custom-file-button-bg);
    border-left: inherit;
    border-radius: 0 $custom-file-border-radius $custom-file-border-radius 0;
  }
}


//   Input group

.input-group {

  > .form-control,
  > .form-control-plaintext,
  > .custom-select,
  > .form-file {
    position: relative; // For focus state's z-index
    flex: 1 1 auto;
    width: 1%;
    min-width: 0; // https://stackoverflow.com/questions/36247140/why-dont-flex-items-shrink-past-content-size
    margin-bottom: 0;

    + .form-control,
    + .custom-select,
    + .form-file {
      margin-left: -$input-border-width;
    }
  }

  // Bring the "active" form control to the top of surrounding elements
  > .form-control:focus,
  > .custom-select:focus,
  > .form-file .form-file-input:focus ~ .form-file-label {
    z-index: 3;
  }

  // Bring the custom file input above the label
  > .form-file .form-file-input:focus {
    z-index: 4;
  }

  > .form-control,
  > .custom-select {
    &:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  // Custom file inputs have more complex markup, thus requiring different
  // border-radius overrides.
  > .form-file {
    display: flex;
    align-items: center;

    &:not(:last-child) .form-file-label,
    &:not(:last-child) .form-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:not(:first-child) .form-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.input-group-prepend,
.input-group-append {
  display: flex;

  // Ensure buttons are always above inputs for more visually pleasing borders.
  // This isn't needed for `.input-group-text` since it shares the same border-color
  // as our inputs.
  .btn {
    position: relative;
    z-index: 2;

    &:focus {
      z-index: 3;
    }
  }

  .btn + .btn,
  .btn + .input-group-text,
  .input-group-text + .input-group-text,
  .input-group-text + .btn {
    margin-left: -$input-border-width;
  }
}

.input-group-prepend {
  margin-right: -$input-border-width;
}

.input-group-append {
  margin-left: -$input-border-width;
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  font-size: $input-font-size-lg;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0
}

// for datatable
.custom-select {
  height: calc(2.125rem + 2px);
  padding: 0.4375rem 2rem 0.4375rem 1rem !important;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: $field-line-height;
  color: $field-color;
  vertical-align: middle;
  border: 1px solid $input-border-color;
  border-radius: 4px;
  appearance: none;
}

