html {
  font-size: $fs-base-mob;
}

@if ($fs-base-mob < $fs-base-tab) {
  @include media-breakpoint-up(lg) {
    html {
      font-size: $fs-base-tab;
    }
  }
}

@if ($fs-base-tab < $fs-base-dsk) {
  @include media-breakpoint-up(lg) {
    html {
      font-size: $fs-base-dsk;
    }
  }
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  transition: color 0.4s, background-color 0.4s, border 0.4s, box-shadow 0.4s;

  &:focus {
    outline: none;
  }
}

img {
  max-width: 100%;
}

strong {
  font-weight: 500;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
p {
  &:last-child {
    margin-bottom: 0;
  }
}

.status-div {
  width: 17rem !important;
  font-weight: bold;
  color: #526484 !important;
}

.status-div-small {
  width: 10rem !important;
  font-weight: bold;
  color: #526484 !important;
}

.lead-source-div {
  width: 20rem !important;
  font-weight: bold;
  color: #526484 !important;
}

.building-width-custom{
  width: 30rem !important;
}

@media (max-width: 768px) {
  .status-div {
    width: 11rem !important;
    font-weight: bold;
    color: #526484 !important;
  }

  .status-div-small {
    width: 8rem !important;
  }

  .building-width-custom{
    width: 19rem !important;
  }

  .lead-source-div {
    width: 17rem !important;
    font-weight: bold;
    color: #526484 !important;
  }
}


