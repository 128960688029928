$animation-duration: 400ms;
$animation-timing: cubic-bezier(0.16, 1, 0.3, 1);
$box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;

@mixin slide-animation($direction, $distance: 2px) {
  @keyframes slide#{$direction}AndFade {
    0% {
      opacity: 0;
      transform: translate#{$direction}($distance);
    }
    100% {
      opacity: 1;
      transform: translate#{$direction}(0);
    }
  }
}

.HoverCardContent {
  border-radius: 6px;
  padding: 20px;
  width: 300px;
  background-color: white;
  box-shadow: $box-shadow;
  animation-duration: $animation-duration;
  animation-timing-function: $animation-timing;
  will-change: transform, opacity;
  z-index: 9999999;

  &[data-side='top'] { animation-name: slideDownAndFade; }
  &[data-side='right'] { animation-name: slideLeftAndFade; }
  &[data-side='bottom'] { animation-name: slideUpAndFade; }
  &[data-side='left'] { animation-name: slideRightAndFade; }
}

.HoverCardArrow {
  fill: white;
}

@include slide-animation('Up', 2px);
@include slide-animation('Right', -2px);
@include slide-animation('Down', -2px);
@include slide-animation('Left', 2px);