@tailwind base;
@tailwind components;
@tailwind utilities;
.ck.ck-content ul,
.ck.ck-content ul li {
    list-style-type: inherit;
}

/* MAKE INPUT[NUMBER] APPEAR LIKE TEXT FIELD */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

/* END OF MAKE INPUT[NUMBER] APPEAR LIKE TEXT FIELD */

.ck.ck-content ul {
    /* Default user agent stylesheet, you can change it to your needs. */
    padding-left: 40px;
}

.ck.ck-content ol,
.ck.ck-content ol li {
    list-style-type: decimal;
}
.ck.ck-content ol {
    /* Default user agent stylesheet, you can change it to your needs. */
    padding-left: 40px;
}
.accordion-header {
    background-color: #f25757;
}

.dropzone-div {
    text-align: center;
    padding: 20px;
    margin: auto;
}

.file-list {
    display: flex;
    flex-wrap: wrap;
    width: 80%;
    margin: 20px auto;
    padding: 10px;
}
.nav-tabs .nav-link.active {
    color: #ce3737;
    border-color: #ce3737;
}
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

/* width */
.no-scroll::-webkit-scrollbar {
    width: 0px;
}

.content-section::-webkit-scrollbar {
    width: 4px;
}
.content-section::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #d69898;
    border-radius: 10px;
}
.content-section::-webkit-scrollbar-thumb {
    background: #ce3737;
    border-radius: 10px;
}
.content-section::-webkit-scrollbar-thumb:hover {
    background: #ce3737;
}

.h-0 {
    height: 0px;
    overflow: hidden;
}

@media screen and (max-width: 768px) {
    .filter-container .content-section.mobile-only {
        position: absolute;
        top: 110%;
        left: 0;
        z-index: 1000000000;
        background-color: #fff;
        border-radius: 6px;
        box-shadow: 0px 1px 8px 0px #00000040;
        min-width: 100%;
        padding: 4px;
        /* font-size: 11px; */
        overflow-x: hidden;
        /* width: max-content; */
        white-space: nowrap;
    }
    .filter-container .title-text {
        overflow-x: hidden;
    }
    .filter-container .content-section.mobile-only.h-0 {
        padding: 0px;
    }
}

@layer components {
    .customInput {
        @apply max-w-lg block w-full shadow-sm bg-[#F6F7FB] text-gray-700 focus:ring-red-500 focus:border-red-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md;
    }
}
