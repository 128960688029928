.gm-style-moc {
  height: unset !important;
}

.sales-funnel-cont {
  display: grid;
  justify-content: center;
  align-items: center;
  gap: 4px;
  position: relative;
  padding: 8px 0 32px;
  background-color: #fff;
  min-height: 270px;
  height: 100%;
}

.listings-acquired {
  height: 100%;
}

.sales-funnel-cont .funnel-img-cont {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: #fff;
  /* z-index: -1; */
  /* absolute top-0 left-0 w-full h-full flex justify-center -z-1 */
}

.funnel-img-cont .funnel-img {
  transform: rotate(180deg);
  min-width: 400px;
  height: 100%;
  /* min-height: 270px; */
  /* rotate-180 min-w-[300px] h-full */
}

.sales-funnel-cont .border-liner {
  position: relative;
  width: 100%;
}

.sales-funnel-cont .border-sharer {
  position: absolute;
  left: 50%;
  /* top: 100%; */
  transform: translateX(-50%);
  height: 4px;
  width: 140px;
}

.filters.month-filters {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  /* width: 100%; */
  /* background-color: #fff; */
}

.filters.month-filters button,
.filters.month-filters .month-picker {
  border-radius: 3px;
  border: none;
  cursor: pointer;
}

.filters.month-filters .month-picker {
  border: 1px solid lightgray;
}

.deal-item-grid {
  height: 100%;
  max-height: 350px;
  overflow-y: auto;
  background-color: #fff;
}

.deal-item-grid .deal-card {
  display: flex;
  flex-direction: column;
  gap: 6px;
  /* flex flex-col gap-3 */
}

.deal-item-grid .deal-card .card-item-cont {
  border-bottom: 1px solid #e1e1e1;
}

.deal-item-grid .deal-card .card-item {
  /* display: flex; */
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  position: relative;
  /* border-radius: 12px; */
  overflow: hidden;
  cursor: pointer;
  transition: all 300ms ease;
  padding: 4px;

  /* flex items-center justify-start gap-2 relative rounded-md overflow-hidden hover:bg-neutral-100 cursor-pointer */
}

.deal-item-grid .deal-card .card-item:hover {
  background-color: lightgray;
}

.deal-item-grid .deal-card .card-item p {
  margin: 0px !important;
  color: #000;
}

.deal-item-grid .deal-card .card-item .deal-number,
.deal-item-grid .deal-card .card-item .deal-status {
  font-weight: 600;
}

.deal-item-grid .deal-card .card-item .deal-type {
  width: 50px;
  height: 50px;
  overflow: hidden;
  background-color: #fff;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid lightgray;
}

.deal-item-grid .deal-card .card-item .deal-type img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.deal-item-grid h6 {
  padding: 2px;
  font-weight: 500;
  color: #8094ae;
  display: flex;
  gap: 4px;
  align-items: center;
  position: relative;
  width: max-content;
  /* py-1 font-semibold text-neutral-500 flex gap-2 items-center relative w-max */
}

.deal-item-grid .deal-item-total {
  padding: 4px 6px;
  border-radius: 100%;
  border: 1px solid #8094ae;
  position: absolute;
  bottom: 30%;
  left: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  /* py-1 px-[11px] rounded-full border border-[#8094ae] absolute bottom-[30%] left-full flex items-center justify-center */
}

.small-text {
  font-size: 12px;
}

.homes-alert {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: max-content;
  transition: all 200ms ease;
}

.activities-row {
  height: 100%;
  align-items: center;
}

h6.deal-heading {
  width: max-content;
  padding: 2px;
  position: relative;
  padding: 4px;
}

h6.deal-heading .deal-number {
  width: max-content;
  height: max-content;
  padding: 0px 4px;
  border-radius: 50%;
  border: 1px solid #8094ae;
  position: absolute;
  left: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.data-card-amount {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.page-visitor {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px;
  text-transform: capitalize;
}

.listing-item-grid {
  height: 100%;
  max-height: 750px;
  overflow-y: auto;
  background-color: #fff;
}

.listing-item-grid .listing-items-cont {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.listing-card-cont {
  margin-top: 0 !important;
  box-shadow: none !important;
}

.listing-card-cont .listing-card-body {
  display: grid;
  grid-template-columns: 1fr 1fr 5fr;
  gap: 0.75rem;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  cursor: pointer;
}

.listing-card-body .listing-data-group {
  text-align: center;
}

.listing-card-body .listing-data-group .amount {
  font-size: 1.5rem;
  font-weight: 600;
}

.listing-card-body .listing-data-group small {
  font-weight: 600;
}

.listing-card-body .listing-card-content .listing-title {
  font-weight: 600;
  /* font-size: 1.1rem; */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
}

.listing-card-shimmer {
  width: 100%;
  animation-name: example;
  animation-duration: 4s;
}

.deal-item-grid .deal-by-leadsource-card {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  border-bottom: 1px solid lightgrey;
}

.agent-deal-closed-card {
  display: flex;
  align-items: center;
  gap: 1rem;
  border-bottom: 1px solid #e3e3e3;
  padding: 0.5rem 0;
  cursor: pointer;
}

.agent-deal-closed-card .content-cont {
  flex: 1;
}

.agent-deal-closed-card .title {
  font-weight: 600;
}

.agent-deal-closed-card .img-cont,
.activity-card-cont .img-cont {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
}

.agent-deal-closed-card .img-cont img.img,
.activity-card-cont .img-cont img.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.live-activities {
  display: grid;
  gap: 1rem;
}

.activity-card-cont .img-cont-comment {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
}

.activity-card-cont {
  padding: 1rem;
  border-radius: 0.5rem;
}

.activity-card-cont.activity-comment-cont {
  font-size: 0.8rem;
}

.activity-card-cont .content-wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.activity-card-cont .content-wrapper .content {
  flex: 1;
  font-size: 1rem;
}

.activity-card-cont .content-wrapper .content.comment {
  flex: 1;
  font-size: 0.8rem;
}

.content-wrapper .content .creator {
  font-weight: 600;
  font-size: 1rem;
}

.activity-card-cont .card-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.activity-card-cont .card-actions .comment-form {
  flex: 1;
  position: relative;
}

.card-actions .comment-form .comment-submit-btn {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.activity-card-cont .card-actions .time-ago {
  width: max-content;
}

.feed-agents {
  display: grid;
  /* gap: 1rem; */
  align-items: center;
  padding: 1rem;
}

/* date picker (months) */
.date-picker-input:focus,
.date-picker-input:active,
.date-picker-input {
  border: none !important;
}

/* Style the select dropdown container */
.select-dropdown {
  position: relative;
}

/* Style the select input */
.select-input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

/* Style the select options container */
.select-options {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 200px; /* Limit the height to make it scrollable if needed */
  background: white;
  border: 1px solid #ccc;
  border-top: none;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  overflow-y: auto; /* Enable scrolling if suggestions exceed max height */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 2;
}

/* Style individual select options */
.select-option {
  padding: 8px 12px;
  cursor: pointer;
  font-size: 14px;
}

/* Highlighted select option */
.select-option:hover,
.select-option:focus {
  background: #f0f0f0;
  outline: none;
}

.custom-modal-width {
  max-width: 90% !important;
}
@keyframes shimmerCard {
  from {
    scale: 1;
    background: #000;
  }

  to {
    scale: 1.3;
    background: #fff;
  }
}

.single-line-textarea {
  min-height: 30px !important;
  resize: none;
  overflow: hidden;
}


@media (min-width: 1500px) and (max-width: 1700px) {
  .product-excerpt-desc {
    max-height: 8rem !important;
  }
}

@media (min-width: 900px) and (max-width: 1499px) {
  .product-excerpt-desc {
    max-height: 5rem !important;
  }
}

@media (max-width: 992px) {
  .field-modal-container {
    flex-direction: column;
  }
  .field-column {
    flex: none;
  }
  .field-column-input {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
  }
}

