.timeline{
    color: $base-light;
    line-height: 1.3;
    + .timeline, &-list + .timeline-head {
        margin-top: 1.75rem;
    }
    &-head{
        font-size: $fx-sz-14;
        color: $base-light;
        margin-bottom: 1rem;
    }
    &-item{
        position: relative;
        display: flex;
        align-items: flex-start;
        &:not(:last-child){
            padding-bottom: 1.5rem;
            &:before{
                position: absolute;
                height: calc(100% - 11px);
                width: 1px;
                background: $border-color;
                content: '';
                top: 13px;
                left: 5px;
            }
        }
    }
    &-status{
        position: relative;
        height: 11px;
        width: 11px;
        border-radius: 50%;
        flex-shrink: 0;
        margin-top: 2px;
        &.is-outline{
            &:after{
                position: absolute;
                height: 7px;
                width: 7px;
                border-radius: 50%;
                background: $white;
                content: '';
                top: 2px;
                left: 2px;
            }
        }
    }
    &-date{
        position: relative;
        color: $base-light;
        width: 90px;
        margin-left: .75rem;
        flex-shrink: 0;
        line-height: 1rem;
        .icon{
            right: 0;
            margin-right: 0.25rem;
            vertical-align: middle;
            color: $base-light;
            display: inline-block;
            position: absolute;
            top: 2px;
        }
    }
    &-data{
        padding-left: 8px;
    }
    &-title{
        font-size: $fx-sz-15;
        color: $base-color;
        margin-bottom: .75rem;
    }
    &-des{
        color: $base-light;
        p{
            margin-bottom: .25rem;
        }
    }
    .time{
        display: block;
        font-size: $fx-sz-12;
        color: $base-light;
    }
}


.timeline-horizontal {
    color: $base-light;
    line-height: 1.3;
    display: flex;
    overflow-x: auto;
    padding-bottom: 1rem;

    &-list {
        display: flex;
        flex-direction: row;
        padding: 0;
        margin: 0;
        align-items: start; /* Center-align items vertically */
    }

    &-item {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1rem;
        min-width: 150px;
        margin-right: 1.5rem;
        text-align: center;

        &:not(:last-child)::after {
            content: "";
            position: absolute;
            top: 12%;
            left: 60%;
            width: 170px !important;
            height: 1px;
            background: #854fff;
            transform: translateY(-50%);
        }
    }

    &-status {
        position: relative;
        height: 11px;
        width: 11px;
        border-radius: 50%;
        flex-shrink: 0;
        margin-bottom: 0.5rem;

        &.is-outline::after {
            position: absolute;
            height: 7px;
            width: 7px;
            border-radius: 50%;
            background: $white;
            content: '';
            top: 2px;
            left: 2px;
        }
    }

    &-date {
        color: $base-light;
        width: auto;
        margin: 0.5rem 0;
        line-height: 1rem;

        .icon {
            margin-left: 0.25rem;
            vertical-align: middle;
            color: $base-light;
            display: inline-block;
            position: relative;
            top: -2px;
        }
    }

    &-data {
        padding-top: 0.5rem;
    }

    &-title {
        font-size: $fx-sz-15;
        color: $base-color;
        margin-bottom: 0.25rem;
    }

    &-des {
        color: $base-light;

        p {
            margin-bottom: 0.25rem;
        }
    }

    .time {
        display: block;
        font-size: $fx-sz-12;
        color: $base-light;
    }
}


@include media-breakpoint-up(sm){
    .timeline + .timeline, .timeline-list + .timeline-head{
        margin-top: 2.5rem;
    }
}
@media (max-width:413px){
    .timeline{
        &-item{
            flex-wrap: wrap;
        }
        &-date{
            width: 80px;
        }
        &-data{
            padding: .75rem 0 0 24px;
        }
    }
}